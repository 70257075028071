/* eslint-disable no-dupe-keys */
import React, { useState, useEffect } from "react";
/*import { Upload } from "../../EntryFile/imagePath";*/

import AuthUser from "../../components/AuthUser";

import { toast, ToastContainer } from 'react-toastify';

import Spinner from "../../components/Spinner";

import { useHistory, Link } from 'react-router-dom';

import Swal from "sweetalert2";

import PropTypes from 'prop-types';

const NovaNoticia = ({ permissaoAdicionar }) => {

  const history = useHistory();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, empresa_selecionada } = AuthUser();
  const [designacao, setDesignacao] = useState('');
  const [acentos, setAcentos] = useState('');
  const [descricao, setDescricao] = useState('');
  const [pagina, setPagina] = useState(1);

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [imagem, setSelectedFile] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [permanecerNaPagina, setPermanecerNaPagina] = useState(true);

   // eslint-disable-next-line no-unused-vars
  const handlePermanecerNaPagina = () => {
    setPermanecerNaPagina(!permanecerNaPagina);
  };

  const navegarParaLista = () => {
    history.push('/dream-pos/noticia/noticialista');
  };

  const navegarParaEdicao = (id) => {
    history.push('/dream-pos/noticia/noticiaedit/'+id);
  };

  useEffect(() => {
    
  }, []);

 
  const handleFileChange = (e) => {
    // Lógica para processar a imagem, se necessário
    setSelectedFile(e.target.files[0]);

    // Restante do código
  };

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const submitForm = async () => {

    if(permissaoAdicionar){
    try {
      toggleLoading(true);

      // Basic form validation
      if (designacao.length < 2) {
        setError('O designação deve ter pelo menos dois caracteres.');
        setLoading(false);

        // Show a error toast
        toast.error('O designação deve ter pelo menos dois caracteres.', {
          position: 'top-right',
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        return;
      }   

      await uploadsala();
      

    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError('Utilizador não autenticado.');

        // Show a error toast
        toast.error('Utilizador não autenticado.', {
          position: 'top-right',
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      } else {
        setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.');

        // Show a error toast
        toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

      }
    } finally {
      toggleLoading(false);
    }

  } else {
    Swal.fire({
      title: "SEM PERMISSÃO",
      text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
      type: "error",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Certo, entendi!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    });
  }
  };


  const uploadsala = async () => {

    if(permissaoAdicionar){
    try {

      const formData = new FormData();

      formData.append('titulo', designacao);
      formData.append('descricao', descricao);
      formData.append('acentos', acentos);
      formData.append('imagem', imagem);
      formData.append('estado', 0);
      formData.append('pagina', pagina);

      formData.append('empresa_id', empresa_selecionada.id);

      const response = await http.post('/noticia/register', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      // Show a success toast
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {
          // Redirecionar para a lista de salas após o cadastro bem-sucedido

          setDesignacao('');
          setDescricao('');
          setSelectedFile(null);

          if (!permanecerNaPagina) {
            navegarParaLista();
          }else {
            navegarParaEdicao(response.data.data.id);
          }
        },
      });

    } catch (error) {
      console.error(error);

      toast.error('Ocorreu um erro ao carregar o sala. Por favor, tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

  } else {
    Swal.fire({
      title: "SEM PERMISSÃO",
      text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
      type: "error",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Certo, entendi!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    });
  }
  };


  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="titles">
            <i alt="Image" className="header-image fa fa-university border p-3 rounded-3"></i>
            <div>
              <h4>Gestão de noticia</h4>
              <h6>Adicionar noticia</h6>
            </div>            
          </div>
          <div className="page-btn">

            <Link to="/dream-pos/noticia/noticialista" className="btn btn-added">              
              <i className="fa fa-table"></i>&nbsp; Lista de noticias
            </Link>

          </div>
        </div>
        {/* /add */}
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-9 col-sm-6 col-12">
                <div className="form-group">
                  <label>Titulo</label>
                  <input type="text" value={designacao} onChange={e => setDesignacao(e.target.value)} />
                </div>


                {
          permissaoAdicionar == 2 ? <>

                <div className="form-group">
                  <label>Pagina</label>

                  <select
                    className="form-control select"
                    onChange={(e) => setPagina(e.target.value)}
                  >
                    <option value="1">Inicio</option>
                    <option value="2">Sobre Nós</option>
                    <option value="3">Especilistas</option>
                    <option value="4">Faqs</option>
                    <option value="5">Unidades</option>
                    <option value="6">Serviços</option>
                    <option value="7">Noticias</option>
                    <option value="8">Contacto</option>

                  </select>
                </div>

                <div className="form-group">
                  <label>Ordem</label>
                  <input type="number" className="form-control" value={acentos} onChange={e => setAcentos(e.target.value)} />
                </div>  

                </>:<></>
        }


                <div className="form-group">
                  <label>Data</label>
                  <input type="text" placeholder="Ex: 23 de Setembro de 2024" value={descricao} onChange={e => setDescricao(e.target.value)} />
                </div>   

                  <div className="form-group">
                  {/* Checkbox "Permanecer na Página" */}
                  <label>
                    <input
                      type="checkbox"
                      checked={permanecerNaPagina}
                      onChange={handlePermanecerNaPagina}
                    />
                    &nbsp; Permanecer na Página depois de salvar
                  </label>
                </div>
                      
              </div>
           
              {/* Restante do código */}
              <div className="col-lg-3 col-sm-6 col-12">
                <div className="form-group">
                  <label>Imagem 1200X700</label>
                  <div className="image-upload image-upload-new">
                    <input type="file" onChange={handleFileChange} />
                    <div className="image-uploads">
                      {imagem ? (
                        <>
                          <img
                            src={URL.createObjectURL(imagem)}
                            alt="Pré-visualização"
                          />
                          <h4>Carregar imagem</h4>
                        </>
                      ) : (
                        <h4>Carregar imagem</h4>
                      )}
                    </div>
                  </div>
                </div>

              

              </div>
              {/* Restante do código */}
              <div className="col-lg-12">
                <a onClick={submitForm} className="btn btn-submit me-2">
                  {loading ? <Spinner /> : 'Salvar'}
                </a>
                <a onClick={navegarParaLista} className="btn btn-cancel">
                  Cancelar
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* /add */}
      </div>

      <ToastContainer />

    </div>
  );
};

NovaNoticia.propTypes = {
  permissaoAdicionar: PropTypes.bool.isRequired,
};

export default NovaNoticia;
